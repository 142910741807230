import type { BrandConfig } from '../../app/interfaces/brand-config';
import { iconConfig } from './iconConfig';

export const brandConfig: BrandConfig = {
  id: 'AARP',
  name: 'AARP',
  appName: 'AARP',
  url: 'www.aarpdriversafety.org',
  hostName: 'aarpdriversafety.org',
  showFee: false,
  feeLabel: '',
  helpPage: 'https://aarpdriversafety.zendesk.com/hc/en-us/',
  siblingSites: [],
  cdiParamKey: '',
  vertical: '',
  craftSite: 'aarp',
  productHandler: '',
  bannerHandler: '',
  cdiParamValueWhenEnabled: '',
  trustpilotId: '',
  trustpilotApiKey: '',
  iterableApiKey: '',
  ambassadorGroupId: '',
  pricingText: '',
  altPricingText: '',
  productCategoryHandle: '',
  checkClassName: '',
  iconConfig: iconConfig,
  ctaText: 'Get Started',
  rsaImageFooterText: 'Allstate has helped over 6,000 AARP drivers get safely back on the road this year.',
  hubspotID: '',
};
