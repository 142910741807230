import { brandConfig } from '@core-mkt/brands/aarp/config';
import type { Env } from '@core-mkt/services/env/env';

export const environment: Env = {
  production: false,
  adobeAnalytics: 'EN104c8b58f14043d4818bf1d2d44dc507-staging',
  envMode: 'stage',
  slugPrefix: '',
  useV3: false,
  craftApiUrl: 'https://craft-v3.ecom-stage.aceable.com/api/',
  xgritCheckoutUrl: 'https://paymentstest.aarpdriversafety.org/checkout/',
  xgritRedirectUrl: 'https://paymentstest.aarpdriversafety.org/aarp-course-selection/',
  xgritApiConfig: {
    url: 'https://stg.xgritapi.com/api/1/',
    aceauth: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
    baseParams: {
      appType: 'DR',
      platform: 'MKT',
      brandId: 'AARP',
    },
  },
  defaultOgImage: '',
  optimizelyId: '',
  optimizelyHandle: 'optimizelyAarpTemplates',
  brandConfig,
  sentryDsn: '',
  segmentId: '',
  segmentCdnProxy: '', //Also need to change the preload on index files
  segmentApiProxy: '',
  twitterHandle: '',
  facebookDomainVerification: '',
  googleSiteVerification: '',
  wistiaVerification: 'Bearer f70f44da17d8dd9a2a09df5c84c1ea2ba9f23d1b5edea43f9a2862cfe1fc99fd',
  ipifyUrl: 'https://api.ipify.org/?format=string',
  xccApiUrl: '',
  gladlyHelpCenterEnvironment: {
    api: 'https://aceable.us-1.gladly.com',
    orgId: 'VJOTwOvcQ6WJr85bp0qL0g',
    brandId: 'JO_ez8tITMeY5CiDoQotzg',
    cdn: 'https://cdn.gladly.com',
    selector: 'gladly-help-center',
  },
};
